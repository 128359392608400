import "core-js/modules/es6.string.link.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'AppQuestTask',
  mixins: [Base],
  props: ['task', 'activeDate'],
  computed: {
    progress: function progress() {
      if (this.task.progress) {
        return {
          width: "".concat(100 / this.task.progress.goal * this.task.progress.complete, "%")
        };
      }

      return {
        width: '12px'
      };
    }
  },
  methods: {
    testNotice: function testNotice(task) {
      var notifyPosition = this.isDesktop ? 'bottom-right' : 'top';
      this.$q.notify({
        group: false,
        html: true,
        position: notifyPosition,
        timeout: 2000,
        classes: 'notificaton-box notificaton-box-complete-quest',
        actions: [{
          icon: 'img:statics/icons/close_notification.svg',
          color: 'white',
          handler: function handler() {}
        }],
        icon: "img:statics/quest/mockIcons/".concat(task.icon, ".svg"),
        message: "\n          <div class=\"wrapper\">\n            <div class=\"content\">\n              <div class=\"title\">".concat(task.title, "</div>\n              <div class=\"reward\">\n                Reward\n                <div class=\"stars\">\n                  <img src=\"/statics/quest/star.png\" srcset='/statics/quest/star@2x.png 2x'>\n                  ").concat(task.stars, "\n                </div>\n                <div class=\"gold\">\n                  <img src=\"/statics/quest/gold.png\" srcset='/statics/quest/gold@2x.png 2x'>\n                  ").concat(task.gold, "\n                </div>\n              </div>\n            </div>\n          </div>\n          ")
      });
    },
    redirectTo: function redirectTo(task) {
      this.testNotice(task);

      if (task.link && !task.isComplete) {
        this.$router.push(task.link);
      }
    }
  }
};