//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BannerPresetPackage from '@/mixins/BannerPresetPackage';
import Cash from '@/mixins/Cash';
import InsufficientPromo from '@/components/GamePopup/InsufficientPromo';
export default {
  name: 'InsufficientFundsPopup',
  mixins: [BannerPresetPackage, Cash],
  components: {
    'app-insufficient-promo': InsufficientPromo
  },
  data: function data() {
    return {
      location: 'empty_balance'
    };
  },
  computed: {
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    balance: function balance() {
      return this.$store.getters['balance/playerBalance'];
    },
    gameMode: function gameMode() {
      return this.$store.getters['player/gameMode'];
    },
    getWinnings: function getWinnings() {
      // eslint-disable-next-line radix
      return parseInt(this.balance.winnings);
    },
    fullscreen: function fullscreen() {
      return this.$q.fullscreen;
    },
    isShowPromoOffer: function isShowPromoOffer() {
      var _this$appMain;

      return !!((_this$appMain = this.appMain) !== null && _this$appMain !== void 0 && _this$appMain.bannerPresetPackage);
    }
  },
  methods: {
    openCashPromo: function openCashPromo() {
      this.isActivePromo = true;
      this.exitFullscreen();
      this.openCashPreset();
      this.$store.dispatch('game/setIsInsufficientFundsPopup', false);
    },
    openWinnings: function openWinnings() {
      this.exitFullscreen();
      this.openCashWinnings();
      this.$store.dispatch('game/setIsInsufficientFundsPopup', false);
    },
    openCashCoins: function openCashCoins() {
      this.exitFullscreen();
      this.openCashGeneral();
      this.$store.dispatch('game/setIsInsufficientFundsPopup', false);
    },
    close: function close() {
      this.$store.ga.clickButton({
        buttonName: 'skip',
        location: this.location
      });
      this.$store.dispatch('game/setIsInsufficientFundsPopup', false);
    },
    exitFullscreen: function exitFullscreen() {
      this.fullscreen.exit();
    }
  }
};