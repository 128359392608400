//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Timer from "../Tournaments/TournamentTimer";
export default {
  components: {
    'app-timer': Timer
  },
  props: {
    sliderData: Array,
    tournament: Object,
    tournTab: Boolean
  },
  data: function data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: true,
        effect: 'fade',
        loop: true,
        autoplay: {
          delay: 2000
        },
        navigation: {
          nextEl: '.game-descr-next',
          prevEl: '.game-descr-prev'
        }
      }
    };
  },
  methods: {
    onSlide: function onSlide() {
      var _this = this;

      var realIndex = this.myswiper.realIndex;
      var lastIndex = this.sliderData.length - 1;

      if (realIndex === 0) {
        this.myswiper.slideToLoop(0, 0, true);
        this.$nextTick(function () {
          return _this.startAutoplay();
        });
      } else if (realIndex === lastIndex) {
        this.myswiper.slideToLoop(realIndex, 0, true);
      }
    },
    stopAutoplay: function stopAutoplay() {
      this.myswiper.autoplay.stop();
    },
    startAutoplay: function startAutoplay() {
      this.myswiper.autoplay.start();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.myswiper) this.myswiper.destroy();
  }
};