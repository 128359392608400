import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TournamentMixin from '@/mixins/TournamentMixin';
import GameMixin from '@/mixins/GameMixin';
import Timer from '@/components/Races/RacesTimer';
import RacesButton from '@/components/Races/RacesButton';
import RacesPrizesModal from '@/components/Races/RacesPrizesModal';
import RacesLeaderboardModal from '@/components/Races/RacesLeaderboardModal';
import RacesRulesModal from '@/components/Races/RacesRulesModal';
export default {
  name: 'GameHeaderUserNavRace',
  components: {
    Timer: Timer,
    RacesPrizesModal: RacesPrizesModal,
    RacesLeaderboardModal: RacesLeaderboardModal,
    RacesRulesModal: RacesRulesModal,
    RacesButton: RacesButton
  },
  mixins: [TournamentMixin, GameMixin],
  data: function data() {
    return {
      isShowPrizesModal: false,
      isShowLeaderboardModal: false,
      isShowRulesModel: false
    };
  },
  computed: {
    isShowBtn: function isShowBtn() {
      return this.isActive && !this.isRaceSubscribed || this.isActive && this.isRaceSubscribed && !this.isTournamentGame;
    },
    tournament: function tournament() {
      return this.$store.getters['tournament/tournament'];
    },
    showedList: function showedList() {
      return this.list.filter(function (item) {
        return item.isShow;
      });
    },
    list: function list() {
      return [{
        id: 1,
        title: 'My rank',
        value: this.rank,
        isShow: this.isRaceSubscribed
      }, {
        id: 2,
        title: 'Plays',
        value: "".concat(this.roundsPlayed, "/").concat(this.tournament.betLimit),
        isShow: this.isRaceSubscribed
      }, {
        id: 3,
        title: 'My points',
        value: this.points,
        isShow: this.isRaceSubscribed
      }, {
        id: 4,
        title: 'Starts',
        value: this.startTime,
        isShow: !this.isActive
      }, {
        id: 5,
        title: 'Time left',
        isTimer: true,
        isShow: !this.isRaceSubscribed && this.isActive
      }, {
        id: 6,
        title: 'Plays',
        value: this.tournament.betLimit,
        isShow: !this.isRaceSubscribed
      }, {
        id: 7,
        title: 'Min play level',
        value: this.tournament.betMin,
        isShow: true
      }];
    },
    isRaceSubscribed: function isRaceSubscribed() {
      return this.tournament.isSubscribed || this.isSubscribed;
    },
    currentUser: function currentUser() {
      return this.$store.getters['tournament/playerData'];
    },
    winners: function winners() {
      return this.$store.getters['tournament/winners'];
    },
    duration: function duration() {
      var _this$tournament;

      return "".concat((_this$tournament = this.tournament) === null || _this$tournament === void 0 ? void 0 : _this$tournament.duration, " min");
    },
    betMin: function betMin() {
      var _this$tournament2;

      return (_this$tournament2 = this.tournament) === null || _this$tournament2 === void 0 ? void 0 : _this$tournament2.betMin;
    },
    userId: function userId() {
      return this.$store.getters['player/profile'].uuid;
    },
    leftRounds: function leftRounds() {
      return this.$store.getters['tournament/leftRounds'];
    },
    position: function position() {
      var _this = this;

      if (this.userId && this.winners && this.winners.length) {
        var user = this.winners.find(function (u) {
          return u.uuid === _this.userId;
        });
        if (user) return user.position;
        return 0;
      }

      return 0;
    },
    bets: function bets() {
      if (this.leftRounds !== null) return this.leftRounds;
      var bets = !this.currentUser.roundsPlayed ? this.tournament.betLimit : this.tournament.betLimit - this.currentUser.roundsPlayed;
      return bets > 0 ? bets : 0;
    }
  }
};