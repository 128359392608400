//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import detect from '@/helpers/detects';
export default {
  name: 'GameHeaderUserNavStatusWidget',
  data: function data() {
    return {
      activeInfo: 0,
      changeActiveInfoTimer: 0,
      reseted: true,
      timeout: 4000
    };
  },
  props: {
    slides: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    slidesType: {
      type: String,
      default: ''
    }
  },
  computed: {
    isShowSlides: function isShowSlides() {
      return this.slidesType;
    },
    slidesLength: function slidesLength() {
      return this.slides.length;
    },
    isIosChrome: function isIosChrome() {
      return detect.isIosChrome();
    }
  },
  methods: {
    changeActiveInfo: function changeActiveInfo() {
      this.reseted = false;
      this.activeInfo = this.activeInfo >= this.slidesLength ? 1 : this.activeInfo + 1;

      if (this.slidesLength === 1 && this.activeInfo === 1) {
        // Stopping slide change if only 1 slide passed
        this.clearChangeActiveInfoTimer();
      }
    },
    setChangeActiveInfoTimer: function setChangeActiveInfoTimer() {
      this.changeActiveInfoTimer = setInterval(this.changeActiveInfo, this.timeout);
    },
    clearChangeActiveInfoTimer: function clearChangeActiveInfoTimer() {
      if (this.changeActiveInfoTimer) {
        this.reseted = true;
        clearInterval(this.changeActiveInfoTimer);
        clearTimeout(this.changeActiveInfoTimer);
      }
    }
  },
  watch: {
    slidesLength: function slidesLength(value) {
      this.clearChangeActiveInfoTimer();

      if (value === 1) {
        if (this.activeInfo === 0) {
          this.changeActiveInfoTimer = setTimeout(this.changeActiveInfo, this.timeout);
        } else {
          this.activeInfo = 1;
        }
      } else if (this.slidesLength) {
        this.setChangeActiveInfoTimer();
      } else {
        this.activeInfo = 0;
      }
    }
  },
  mounted: function mounted() {
    if (this.slidesLength) {
      this.setChangeActiveInfoTimer();
    }
  },
  destroyed: function destroyed() {
    this.clearChangeActiveInfoTimer();
  }
};