export default {
  computed: {
    currentGame: function currentGame() {
      return this.$store.getters['game/currentGame'];
    },
    tournament: function tournament() {
      return this.$store.getters['tournament/tournament'];
    },
    isTournamentGame: function isTournamentGame() {
      var _this$tournament;

      if (this.currentGame && this.currentGame.game) return (_this$tournament = this.tournament) === null || _this$tournament === void 0 ? void 0 : _this$tournament.isTournamentGame; // if (this.currentGame && this.currentGame.game) return this.tournament.gameSlug === this.currentGame.game.slug;

      return false;
    },
    isSubscribed: function isSubscribed() {
      return this.$store.getters['tournament/isSubscribed'](this.tournament.id);
    }
  }
};