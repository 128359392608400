import "core-js/modules/es6.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AppQuestInfo from '@/components/Quest/QuestInfo';
import AppQuestTask from '@/components/Quest/QuestTask';
import AppQuestWinNotification from '@/components/Quest/QuestWinNotification';
export default {
  name: 'QuestGamePopup',
  props: {
    isWhiteTheme: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'app-quest-info': AppQuestInfo,
    'app-quest-task': AppQuestTask,
    'app-quest-winner-notification': AppQuestWinNotification
  },
  data: function data() {
    return {
      isTasksListSqueeze: false,
      infoDialog: false
    };
  },
  computed: {
    questData: function questData() {
      return this.$store.getters['quest/questData'];
    },
    uncollectedQuestPrize: function uncollectedQuestPrize() {
      return this.$store.getters['quest/uncollectedQuestPrize'];
    },
    activeDate: function activeDate() {
      return this.questData.progress.find(function (day) {
        return !day.isEnded;
      });
    }
  },
  methods: {
    initTasksState: function initTasksState() {
      if (this.isWhiteTheme) {
        this.isTasksListSqueeze = true;
      }
    },
    showInfoDialog: function showInfoDialog() {
      this.infoDialog = true;
    },
    closeInfoDialog: function closeInfoDialog() {
      this.infoDialog = false;
    },
    joinQuest: function joinQuest() {
      this.questData.isRegister = true;
    },
    collectPrize: function collectPrize() {
      this.questData.isRegister = false;
    }
  },
  mounted: function mounted() {
    this.initTasksState();
  }
};