//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AppQuestInfo',
  props: {
    questData: {
      type: Object || null,
      default: null
    },
    isGamePopup: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeInfoDialog: function closeInfoDialog() {
      this.$emit('closeInfoDialog');
    }
  }
};