import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GameMixin from '@/mixins/GameMixin';
import AppQuestGamePopup from '@/components/Quest/QuestGamePopup';
import AppGameHeaderUserNavActions from "./__GameHeaderUserNavActions";
import AppGameHeaderUserNavRace from "./__GameHeaderUserNavRace";
import AppGameHeaderUserNavStatusWidget from "./__GameHeaderUserNavStatusWidget";
import AppGameToggleFavorite from "./__GameToggleFavorite";
export default {
  name: 'GameHeaderUserNav',
  components: {
    AppGameHeaderUserNavActions: AppGameHeaderUserNavActions,
    AppGameHeaderUserNavRace: AppGameHeaderUserNavRace,
    AppGameHeaderUserNavStatusWidget: AppGameHeaderUserNavStatusWidget,
    AppQuestGamePopup: AppQuestGamePopup,
    AppGameToggleFavorite: AppGameToggleFavorite
  },
  mixins: [GameMixin],
  data: function data() {
    return {
      profileDropdownShowed: false,
      profileDropdownShowedOnce: false,
      atLeastOneOpened: false,
      lastSeenTournament: null
    };
  },
  computed: {
    questData: function questData() {
      return this.$store.getters['quest/questData'];
    },
    showBadge: function showBadge() {
      var _this$tournament;

      return (!this.isTournamentGame || this.isTournamentGame && !this.isRaceSubscribed) && this.lastSeenTournament !== ((_this$tournament = this.tournament) === null || _this$tournament === void 0 ? void 0 : _this$tournament.id);
    },
    gameMode: function gameMode() {
      return this.$store.getters['player/gameMode'];
    },
    raceAlertMsg: function raceAlertMsg() {
      var alert = this.$store.getters['tournament/raceAlertMsg'];
      return alert && (alert.action === 'min-alert' || alert.action === 'max-alert') ? alert : undefined;
    },
    getRaceAlertMsgLabel: function getRaceAlertMsgLabel() {
      return this.raceAlertMsg.action === 'min-alert' ? 'GameHeaderUserNav.raceAlertMinBet' : 'GameHeaderUserNav.raceAlertMaxBet';
    },
    getProgress: function getProgress() {
      var progress = this.isShowRaceInfoInWidget ? this.raceTimerProgress : this.percentToNextLevel;
      return !this.isShowRace || this.raceInited ? progress : 0; // Don't fill progress bar until race request will complete
    },
    tournament: function tournament() {
      return this.$store.getters['tournament/tournament'];
    },
    isShowTournament: function isShowTournament() {
      return false; // if (this.gameMode === 'SweepStakes' || (this.tournament && Array.isArray(this.tournament) && this.tournament.length)) return false;
      // return this.tournament;
    },
    getWidgetSlidesType: function getWidgetSlidesType() {
      var slidesType = '';

      if (this.isShowTournament) {
        slidesType = 'tournament';
      }

      return slidesType;
    },
    getWidgetSlides: function getWidgetSlides() {
      var slides;

      switch (this.getWidgetSlidesType) {
        case 'tournament':
          {
            var raceInfo = [{
              class: '',
              value: !this.raceAlertMsg ? this.getPlayerPlace : "".concat(this.raceAlertMsg.bet, " ").concat(this.raceAlertMsg.currency),
              label: !this.raceAlertMsg ? 'My<br>rank' : this.getRaceAlertMsgLabel
            }];
            var raceInfoExt = [{
              class: '',
              value: this.getPlayerBets,
              label: 'Plays'
            }]; // const goRace = [
            //   {
            //     class: 'small-text',
            //     value: '',
            //     label: 'SWITCH<br>TO THE<br>RACE',
            //   },
            // ];
            // const joinRace = [
            //   {
            //     class: 'small-text',
            //     value: '',
            //     label: 'JOIN<br>RACE',
            //   },
            // ];

            if (!this.isTournamentGame) break;else if (this.isTournamentGame && !this.isRaceSubscribed) break;else slides = !this.raceAlertMsg ? raceInfo.concat(raceInfoExt) : raceInfo;
            break;
          }

        default:
          break;
      }

      return slides; // Returning slides array OR undefined
    },
    userId: function userId() {
      return this.$store.getters['player/profile'].uuid;
    },
    winners: function winners() {
      return this.$store.getters['tournament/winners'];
    },
    getPlayerPlace: function getPlayerPlace() {
      var _this = this;

      if (this.currentUser.place) {
        return this.currentUser.place;
      }

      if (this.userId && this.winners && this.winners.length) {
        var user = this.winners.find(function (u) {
          return u.uuid === _this.userId;
        });
        if (user) return user.position;
        return 0;
      }

      return 0;
    },
    leftRounds: function leftRounds() {
      return this.$store.getters['tournament/leftRounds'];
    },
    getPlayerBets: function getPlayerBets() {
      var _this$currentUser, _this$tournament2, _this$tournament3, _this$currentUser2;

      if (this.leftRounds !== null) return this.leftRounds;
      var bets = !((_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && _this$currentUser.roundsPlayed) ? (_this$tournament2 = this.tournament) === null || _this$tournament2 === void 0 ? void 0 : _this$tournament2.betLimit : ((_this$tournament3 = this.tournament) === null || _this$tournament3 === void 0 ? void 0 : _this$tournament3.betLimit) - ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.roundsPlayed);
      return bets > 0 ? bets : 0;
    },
    currentUser: function currentUser() {
      return this.$store.getters['tournament/playerData'];
    },
    isSubscribed: function isSubscribed() {
      return this.$store.getters['tournament/isSubscribed'](this.tournament.id);
    },
    currentRaceId: function currentRaceId() {
      return this.$store.getters['tournament/currentRaceId'];
    },
    isRaceSubscribed: function isRaceSubscribed() {
      return this.tournament.isSubscribed || this.isSubscribed;
    }
  },
  methods: {
    hideDropdown: function hideDropdown() {
      this.profileDropdownShowed = false;
    },
    dropdownToggle: function dropdownToggle() {
      var _this$tournament4;

      localStorage.setItem('lastSeenTournament', (_this$tournament4 = this.tournament) === null || _this$tournament4 === void 0 ? void 0 : _this$tournament4.id);
      this.setLastSeenTournament();
      this.$store.gaCash.clickButton({
        location: 'gameplay',
        buttonName: !this.profileDropdownShowed ? 'gameplay_menu_open' : 'gameplay_menu_close'
      });
      this.profileDropdownShowed = !this.profileDropdownShowed;
      if (!this.profileDropdownShowedOnce) this.profileDropdownShowedOnce = true;
    },
    setLastSeenTournament: function setLastSeenTournament() {
      this.lastSeenTournament = Number(localStorage.getItem('lastSeenTournament'));
    }
  },
  created: function created() {
    this.setLastSeenTournament();
  }
};