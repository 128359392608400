//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'GameToggleFavorite',
  props: {},
  data: function data() {
    return {};
  },
  computed: {
    btnText: function btnText() {
      return !this.isInFavorites ? 'Add game to favorites' : 'Remove game from favorites';
    },
    currentGame: function currentGame() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['game/currentGame']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.game;
    },
    favorites: function favorites() {
      return this.$store.getters['player/favoriteGames'];
    },
    favObj: function favObj() {
      if (this.favorites) {
        var favObj = {}; // eslint-disable-next-line no-plusplus

        for (var i = 0; i < this.favorites.length; i++) {
          favObj[this.favorites[i]] = this.favorites[i];
        }

        return favObj;
      }

      return null;
    },
    isInFavorites: function isInFavorites() {
      return this.currentGame.id === this.favObj[this.currentGame.id];
    }
  },
  methods: {
    toggleFavorite: function toggleFavorite() {
      this.$store.dispatch('player/toggleFavorite', this.currentGame.id);
      this.$q.notify({
        message: !this.isInFavorites ? 'Game added to favorites' : 'Game removed from favorites',
        position: this.isDesktop ? 'bottom-right' : 'bottom',
        timeout: 1500,
        icon: 'img:statics/games/favor_notify.svg',
        classes: 'notificaton-box notificaton-box--favorite'
      });
    }
  }
};