//
//
//
//
//
//
//
export default {
  props: {
    message: String
  }
};