//
//
//
//
//
export default {
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  mounted: function mounted() {
    console.log(this.message);
  }
};