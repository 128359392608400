//
//
//
//
//
//
//
//
//
//
import AppGameHeaderUserNav from "./__GameHeaderUserNav";
export default {
  name: 'GameHeader',
  components: {
    AppGameHeaderUserNav: AppGameHeaderUserNav
  },
  props: {
    game: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    getGameSlug: function getGameSlug() {
      var _this$game$game;

      return (_this$game$game = this.game.game) === null || _this$game$game === void 0 ? void 0 : _this$game$game.slug;
    },
    isMobileLayout: function isMobileLayout() {
      return !this.isDesktop;
    },
    getGameTitle: function getGameTitle() {
      var _this$game$game2;

      return (_this$game$game2 = this.game.game) === null || _this$game$game2 === void 0 ? void 0 : _this$game$game2.title;
    },
    isShowChangeModeBtn: function isShowChangeModeBtn() {
      return this.game.game && !this.game.isMoney && !this.$store.getters['player/profile'].isGuest;
    }
  },
  methods: {}
};